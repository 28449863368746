import Hrvatska from "../../assets/flags/croatia.png";
import Poljska from "../../assets/flags/poljska.png";
import Njemacka from "../../assets/flags/njemacka.png";
import Slovenija from "../../assets/flags/slovenia.png";

export const testemonals = [
  {
    name: "Elena",
    country: "Croatia",
    flag: Hrvatska,
    date: "25/08/2021",
    rating: 10,
    description:
      "Vrhunski uređeni i opremljeni aprtmani. Isto tako izuzetno gostoljubivi domaćini, koji će sve napraviti da se osjećate kao u svom domu. Odlična lokacija za obitelji sa djecom kao i za miran odmor. Velike pohvale i preporuke. Sigurno ćemo se vratiti.",
  },
  {
    name: "Maja",
    country: "Croatia",
    flag: Hrvatska,
    date: "30/07/2021",
    rating: 10,
    description:
      "Vlasnici su vrlo pristupačni, ugodni i ljubazni te su nam pružili vrlo lijepu dobrodošlicu uz vino i druženje. Apartman je ispunio sva naša očekivanja. S balkona se pruža prekrasan pogled na more. Sve je onako kako je i opisano, uredno i čisto. Sama lokacija je idealna za sve koji traže mir.",
  },
  {
    name: "Cyryl",
    country: "Poljska",
    flag: Poljska,
    date: "21/09/2021",
    rating: 10,
    description:
      "We wish to thank Family Savar for letting us stay at VillaMar.We spent there wonderful 5 days. The apartment was cosy and well equipped. To our surprise a supper was waiting for us in the refrigerator plus something extra 😉 The owner Mr Josip,was very friendly and open, Language barrier was non existent!",
  },
  {
    name: "Claudia",
    country: "Njemačka",
    flag: Njemacka,
    date: "29/08/2021",
    rating: 10,
    description:
      "We were 2 families with 2 small children (2 and 6 years). We were very nice welcomed by Snjezana and Josip. They are very nice people and helped us during our entire stay if we needed something or with informations and hints related to what we can visit. We really enjoyed our time spent in Villa Mar.",
  },
  {
    name: "Daniel",
    country: "Croatia",
    flag: Hrvatska,
    date: "19/08/2021",
    rating: 10,
    description:
      "The apartment is well equipped with hair dryer, gas stove, coffee maker, electric kettle, toast maker, refrigerator with a small freezer and some spices (sugar, salt, pepper...) The appliances were all new, or at least they looked and functioned as new.",
  },
  {
    name: "Justyna",
    country: "Poljska",
    flag: Poljska,
    date: "23/09/2021",
    rating: 10,
    description:
      "Apartament przewyższył nasze oczekiwania, gospodarze stworzyli niesamowitą atmosferę, przez co można było poczuć się jak w domu. Apartament był bardzo dobrze wyposażony, niczego nie brakowało. Otoczenie jest spokojne i ciche, sprzyja odpoczynkowi, idealne dla rodzin z dziećmi. Polecam!",
  },
  {
    name: "Adrian",
    country: "Poljska",
    flag: Poljska,
    date: "20/08/2021",
    rating: 10,
    description:
      "Cicha i spokojna okolica z dostępem do morza, bardzo mili gospodarze, w pełni wyposażony apartament, polecam",
  },
  {
    name: "Malgorzata",
    country: "Poljska",
    flag: Poljska,
    date: "11/08/2021",
    rating: 10,
    description:
      "Obiekt położony w małej ale bardzo pięknej wiosce , z tarasu widok na morze, w apartamencie bardzo czysto ,przepiękna haftowana pościel , czyste ręczniki ,suszarka do włosów. Gospodarze przyjęli nas bardzo serdecznie, przemili ludzie i bardzo pomocni",
  },
  {
    name: "Izabella",
    country: "Poljska",
    flag: Poljska,
    date: "14/07/2021",
    rating: 10,
    description:
      "Miłym zaskoczeniem była przygotowana kolacja wraz z butelką wina i chłodnym piwem. Właściciele bardzo sympatyczni i bardzo kontaktowi w każdej chwili służący pomocą ,znający historię wyspy.Apartament bardzo przestronny i czysty z widokiem na morze i góry.Plaża blisko apartamentu.",
  },
  {
    name: "Anonimno",
    country: "Slovenia",
    flag: Slovenija,
    date: "07/08/2021",
    rating: 10,
    description:
      "Bližina morja, prostoren, nov in popolnoma opremljen apartma, zelo prijazni gostitelji. Odlično!",
  },
];
