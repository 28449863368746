import React from "react";

import Nav from "../components/Nav/Nav";
import Welcome from "../components/Welcome/Welcome";
import ApartmantList from "../components/ApartmantList/ApartmantList";
import Testimonials from "../components/Testimonials/Testimonials";
import AboutUs from "../components/AboutUs/AboutUs";
import GeneralInfo from "../components/GeneralInfo/GeneralInfo";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
      <Nav />
      <Welcome />
      <GeneralInfo />
      <ApartmantList />
      <Testimonials />
      <AboutUs />
      <Footer />
    </>
  );
};

export default Home;
