import React from "react";

import { generalApartmantInfo } from "../ApartmantList/const";

const ApartmantAsset = () => {
  return (
    <div className="apartmant-asset my-5">
      <div className="apartmant-asset-box">
        <h4>
          <i className="fas fa-utensils"></i> Kitchen
        </h4>
        <ul>
          {generalApartmantInfo.kitchen.map((item, i) => (
            <li key={i} className="apartmant-asset-solo">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="apartmant-asset-box">
        <h4>
          <i className="fas fa-sink"></i> Bathroom
        </h4>
        <ul>
          {generalApartmantInfo.bathroom.map((item, i) => (
            <li key={i} className="apartmant-asset-solo">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="apartmant-asset-box">
        <h4>
          <i className="far fa-eye"></i> View
        </h4>
        <ul>
          {generalApartmantInfo.view.map((item, i) => (
            <li key={i} className="apartmant-asset-solo">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="apartmant-asset-box">
        <h4>
          <i className="fas fa-smoking-ban"></i> Smoking
        </h4>
        <ul>
          {generalApartmantInfo.smoking.map((item, i) => (
            <li key={i} className="apartmant-asset-solo">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="apartmant-asset-box">
        <h4>
          <i className="fas fa-parking"></i> Parking
        </h4>
        <ul>
          {generalApartmantInfo.parking.map((item, i) => (
            <li key={i} className="apartmant-asset-solo">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="apartmant-asset-box">
        <h4>
          <i className="fas fa-home"></i> Details
        </h4>
        <ul>
          {generalApartmantInfo.apartmantDetails.map((item, i) => (
            <li
              key={i}
              style={{ listStylePosition: "outside" }}
              className="apartmant-asset-solo"
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ApartmantAsset;
