import React from "react";

const Divider = () => {
  return (
    <div
      style={{
        height: 2,
        width: 75,
        backgroundColor: "red",
        marginBottom: "3vh",
      }}
    />
  );
};

export default Divider;
