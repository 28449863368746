import { generatePhotoUrl } from "../../services/helpers";

export const apartmants = [
  {
    id: 1,
    name: "Luna",
    link: `/apartmant/1`,
    size: 51,
    price: 0,
    description:
      "This apartment has a kitchenware, barbecue and dining area. This apartment has a kitchenware, barbecue and dining area. This apartment has a kitchenware, barbecue and dining area.",
    thumbnail: "/images/apartments/Luna/2.jpg",
    images: generatePhotoUrl("Luna", 7),
    doubleBed: 1,
    sofaBed: 1,
    adults: 3,
    children: 1,
    bathroom: 1,
    premium: false,
  },
  {
    id: 2,
    name: "Perla",
    link: `/apartmant/2`,
    size: 68,
    price: 0,
    description:
      "This apartment features a soundproofing, seating area and stovetop. This apartment features a soundproofing, seating area and stovetop. This apartment features a soundproofing, seating area and stovetop.",
    thumbnail: "/images/apartments/Perla/1.jpg",
    images: generatePhotoUrl("Perla", 17),
    doubleBed: 2,
    sofaBed: 1,
    adults: 6,
    children: 1,
    bathroom: 2,
    premium: true,
  },
  {
    id: 3,
    name: "Laganini",
    link: `/apartmant/3`,
    size: 51,
    price: 0,
    description:
      "This apartment has a stovetop, soundproofing and balcony. This apartment has a stovetop, soundproofing and balcony. This apartment has a stovetop, soundproofing and balcony.",
    thumbnail: "/images/apartments/Laganini/2.jpg",
    images: generatePhotoUrl("Laganini", 10),
    doubleBed: 1,
    sofaBed: 1,
    adults: 4,
    children: 1,
    bathroom: 1,
    premium: false,
  },
  {
    id: 4,
    name: "New",
    link: `/apartmant/4`,
    size: 51,
    price: 0,
    description:
      "This apartment features a patio, soundproofing and tea/coffee maker. This apartment features a patio, soundproofing and tea/coffee maker. This apartment features a patio, soundproofing and tea/coffee maker.",
    thumbnail: "/images/apartments/New/2.jpg",
    images: generatePhotoUrl("New", 18),
    doubleBed: 1,
    sofaBed: 1,
    adults: 4,
    children: 1,
    bathroom: 1,
    premium: false,
  },
  {
    id: 5,
    name: "Solis",
    link: `/apartmant/5`,
    size: 51,
    price: 0,
    description:
      "This apartment features a patio, soundproofing and tea/coffee maker. This apartment features a patio, soundproofing and tea/coffee maker. This apartment features a patio, soundproofing and tea/coffee maker.",
    thumbnail: "/images/apartments/Solis/2.jpg",
    images: generatePhotoUrl("Solis", 12),
    doubleBed: 1,
    sofaBed: 1,
    adults: 4,
    children: 1,
    bathroom: 1,
    premium: false,
  },
  {
    id: 6,
    name: "Family",
    link: `/apartmant/6`,
    size: 68,
    price: 0,
    description:
      "This apartment features a soundproofing, seating area and stovetop. This apartment features a soundproofing, seating area and stovetop. This apartment features a soundproofing, seating area and stovetop.",
    thumbnail: "/images/apartments/Family/1.jpg",
    images: generatePhotoUrl("Family", 10),
    doubleBed: 2,
    sofaBed: 1,
    adults: 6,
    children: 1,
    bathroom: 2,
    premium: true,
  },
];

export const generalApartmantInfo = {
  cards: [
    "Entire apartment",
    "balcony",
    "sea view",
    "garden view",
    "mountain view",
    "air conditioning",
    "paved courtyard",
    "private bathroom",
    "flat-screen TV",
    "soundproofing",
    "BBQ",
    "free Wi-Fi",
    "free parking",
  ],
  kitchen: [
    "refrigerator",
    "stovetop",
    "toaster",
    "oven",
    "washing machine",
    "kettle",
    "kitchenware",
    "kitchen table",
    "high chair",
  ],
  bathroom: [
    "free toiletries",
    "toilet",
    "bathtub or shower",
    "towels",
    "hairdryer",
    "toilet paper",
  ],
  view: ["sea view", "garden view", "mountain view"],
  apartmantDetails: [
    "upper floors accessible by stairs only",
    "linen",
    "wardrobe or closet",
    "private apartment in building",
    "hand sanitizer",
    "coffee/tea maker",
    "air conditioning",
    "safe",
    "wooden or parquet floor",
    "tiled/marble floor",
    "soundproofing",
    "private entrance",
    "mosquito net",
    "iron",
    "connecting rooms",
    "heating",
    "carpet",
    "refrigerator",
    "kettle",
    "stovetop",
    "kitchenware",
    "toaster",
    "kitchen table",
    "oven",
    "high chair",
    "kitchen",
    "washing machine",
    "sofa",
    "seating area",
    "dining area",
    "TV",
    "flat-screen TV",
    "balcony",
    "outdoor furniture",
    "paved courtyard",
    "BBQ",
    "socket near the bed",
    "folding bed",
    "dryer",
    "sofa bed",
    "individual air conditioning unit in each accommodation",
  ],
  smoking: ["Smoking prohibited"],
  parking: [
    "Free private parking is available on site and no reservation is needed",
  ],
};
