import React from "react";
import { Container } from "react-bootstrap";
import Divider from "../Divider/Divider";

const AboutUs = () => {
  return (
    <Container>
      <div style={{ margin: "100px 0" }}>
        <h2>Contact</h2>
        <Divider />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10vh 0",
            background:
              "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(71,100,113,1) 100%, rgba(255,255,255,1) 100%)",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <button
            style={{
              backgroundColor: "#476471",
              color: "white",
              border: "unset",
              borderRadius: "0.25rem",
              padding: "0.375rem 0.75rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-envelope"></i>
            <a
              href="mailto:snjezana.savar@gmail.com"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3 style={{ margin: 0 }}>Contact Us</h3>
            </a>
          </button>
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
