import React from "react";
import { useNavigate } from "react-router-dom";

const Apartmant = (props) => {
  const navigate = useNavigate();

  const { link, bathroom, name, doubleBed, adults, thumbnail } = props;

  const handleApartmantClicked = () => {
    navigate(link);
  };

  return (
    <div
      className="apartmant"
      style={{ backgroundImage: `url("${thumbnail}")` }}
      onClick={handleApartmantClicked}
    >
      <div className="apartmant-icon">
        <i className="far fa-heart"></i>
      </div>
      <div className="apartmant-footer">
        <div className="infobox">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/*    <p className="rating">10</p> */}
            <h4>{name}</h4>
          </div>
          <div className="info">
            <p>{adults} adults</p>
            <p>{bathroom} bathroom(s)</p>
            <p>{doubleBed} bed(s)</p>
          </div>
        </div>
        <div className="icon">
          <i className="fas fa-home"></i>
        </div>
      </div>
    </div>
  );
};

export default Apartmant;
