import React from "react";

const IconsBar = (props) => {
  const { adults, bathroom, doubleBed, size, newStyle = false } = props;
  return (
    <>
      {newStyle ? (
        // New Styling component
        <div className="icons-bar-new">
          <p>
            Number of guests
            <i className="fas fa-user-friends"></i>
            {adults || 0}
          </p>
          <p>
            Number of beds
            <i className="fas fa-bed"></i>
            {doubleBed || 0}
          </p>
          <p>
            Size m<sup>2</sup>
            <i className="far fa-square"></i>
            {size || 0}
          </p>
          {/*   <p>
            Price <i className="fas fa-euro-sign"></i>
            {price || 0}
          </p> */}
        </div>
      ) : (
        // Old Styling component
        <div className="icons-bar">
          <div className="iconbox">
            <i className="fas fa-user-friends"></i>
            <span> {adults || 0}</span>
          </div>
          <div className="iconbox">
            <i className="fas fa-bath"></i>
            <span>{bathroom || 0}</span>
          </div>
          <div className="iconbox">
            <i className="fas fa-bed"></i>
            <span>{doubleBed || 0}</span>
          </div>
          <div className="iconbox">
            <i className="far fa-square"></i>
            <span>
              {size || 0} m<sup>2</sup>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default IconsBar;
