import React from "react";
import { Container } from "react-bootstrap";

import CroatiaFlag from "../../assets/flags/croatia.png";

const Footer = () => {
  return (
    <footer>
      <Container>
        <div
          style={{ paddingLeft: "20px", paddingTop: "20px" }}
          className="social-links"
        >
          <h1>Find us on social media</h1>
          <i className="fab fa-facebook"></i>
          <i className="fab fa-instagram-square"></i>
        </div>
        <div className="footer__box">
          <div className="footer-left">
            <h1>Villa Mar | Pag</h1>
            <address>
              <p>
                <i style={{ marginRight: 25 }} className="fas fa-female"></i>
                Snježana Savar
              </p>
              <p>
                <i className="fas fa-envelope"></i>
                <a href="mailto:snjezana.savar@gmail.com">
                  snjezana.savar@gmail.com
                </a>
              </p>
              <p>
                <i className="fas fa-phone-square"></i>
                <a href="tel:+385 98 383 423">+385 98 383 423</a>
              </p>
              <p>
                <i className="fas fa-map-marker-alt"></i>
                154 Kosljun, 23250 Pag
              </p>
              <img src={CroatiaFlag} alt="flag" />
              <p style={{ display: "inline-block" }}>Croatia</p>
            </address>
          </div>
          <div className="footer-right">
            <h1>Disclosure</h1>
            <p>
              It is our pleasure to extend a cheerful welcome to you all! Your
              presence makes us very happy. We hope you'll enjoy your vacation
              in Košljun just as much as we do every year :) The activities we
              like to do in Košljun: spending the day on the Košljun Beach
              (called Šumica), watching the sunset, snorkeling, having a BBQ,
              watching the waves, taking a road trip, going night swimming,
              going for a long bike ride/ for a hike/ for a walk, making
              traditional meals and exploring the island of Pag.
            </p>
          </div>
        </div>
      </Container>
      <div className="footer-promotion">
        <p>
          Website made by <span>Renato Pavlić</span>
        </p>
        <p>
          Do you want a website? Contact me at{" "}
          <a href="mailto:renato.poslovni@gmail.com">
            renato.poslovni@gmail.com
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
