import React from "react";
import { Container, Carousel } from "react-bootstrap";
import Divider from "../Divider/Divider";
import { generatePhotoUrl } from "../../services/helpers";

const GeneralInfo = () => {
  const photos = generatePhotoUrl("General", 14);
  return (
    <Container className="general-info">
      <h1>The most popular content</h1>
      <Divider />
      <div className="icons-container">
        <div className="icon-box">
          <i class="fas fa-parking"></i>
          <p>free parking</p>
        </div>
        <div className="icon-box">
          <i class="fas fa-umbrella-beach"></i>
          <p>near beach</p>
        </div>
        <div className="icon-box">
          <i class="fas fa-wifi"></i>
          <p>free wi-fi</p>
        </div>
        <div className="icon-box">
          <i class="fas fa-users"></i>
          <p>family rooms</p>
        </div>
        <div className="icon-box">
          <i class="fas fa-smoking-ban"></i>
          <p>no smoking</p>
        </div>
        <div className="icon-box">
          <i class="fas fa-utensils"></i>
          <p>barbecue</p>
        </div>
      </div>
      <div style={{ margin: "50px 0" }}>
        <Carousel>
          {Array.isArray(photos) &&
            photos.length > 0 &&
            photos.map((t, i) => (
              <Carousel.Item interval={5000} key={i}>
                <img
                  style={{ maxHeight: "70vh", width: "100%" }}
                  src={t}
                  alt="First slide"
                />
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
      <h1>About Villa Mar</h1>
      <Divider />
      <p>
        This property is a 3-minute walk from the beach. Located in Pag on the
        island of the same name, VillaMar offers accommodation with free private
        parking.
      </p>
      <p>
        Units are air conditioned and offer garden views and include parquet
        floors, a private bathroom, a flat-screen TV, free WiFi, a wardrobe, a
        living room, an equipped kitchen and a patio. Units also include a
        seating and dining area.
      </p>
      <p>The property offers a sun terrace.</p>
      <p>
        A garden with barbecue facilities is available on site, and guests can
        enjoy hiking and snorkelling in the surrounding area.
      </p>
      <p>
        Villa Mar is located 24 km from Novalja and 13 km from Pag. The nearest
        airport is Zadar Airport, 40 km from the property.
      </p>
    </Container>
  );
};

export default GeneralInfo;
