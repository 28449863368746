import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { apartmants } from "../components/ApartmantList/const";
import IconsBar from "../components/IconsBar/IconsBar";
import Nav from "../components/Nav/Nav";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";
import Divider from "../components/Divider/Divider";
import BeachCard from "../components/BeachCard/BeachCard";
import AboutUs from "../components/AboutUs/AboutUs";
import ApartmantAsset from "../components/ApartmantAsset/ApartmantAsset";
import Gallery from "../components/Gallery/Gallery";

const Details = () => {
  const { id } = useParams();
  const [apartmant, setApartmant] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (apartmants[id - 1]) setApartmant(apartmants[id - 1]);
  }, [id]);

  useEffect(() => {
    if (id > apartmants.length) {
      navigate("/404");
    }

    window.scrollTo({
      top: 0,
      smooth: true,
    });
  }, [id, navigate]);

  return (
    <>
      <Nav />
      <main className="apartmant__details">
        <Container>
          <div className="gallery">
            <div className="gallery-left">
              <img
                src={apartmant.images?.[0]}
                className="gallery-img small"
                alt="gallery-small"
              />
              <img
                src={apartmant.images?.[1]}
                className="gallery-img small"
                alt="gallery-small"
              />
            </div>
            <div className="gallery-right">
              <img
                src={apartmant.images?.[2]}
                className="gallery-img large"
                alt="gallery-large"
              />
            </div>
          </div>
        </Container>
        <div className="details-bar">
          <h2>{apartmant.name}</h2>
          <IconsBar
            size={apartmant.size}
            bathroom={apartmant.bathroom}
            adults={apartmant.adults}
            doubleBed={apartmant.doubleBed}
            newStyle
            price={apartmant.price || 0}
          />
        </div>
        <Gallery apartment={apartmant} />
        <Container>
          <section className="section-info">
            <h2>{apartmant.name}</h2>
            <Divider />
            <div className="details-information-container  text-center">
              <ul>
                <li>{apartmant.adults} guests</li>
                <li>{apartmant.doubleBed} bedrooms</li>
                <li>{apartmant.bathroom} bathrooms</li>
                <li>{apartmant.sofaBed} sofa bed </li>
              </ul>
              <div className="details-review">
                <h6>10</h6>
                <p>review score</p>
              </div>
            </div>
            <p className="apartmant-description">{apartmant.description}</p>
            <h2 className="mt-5 beaches-title">Closest beaches</h2>
            <Divider />
            <div className="closest-beaches">
              <BeachCard
                name="Camp Beach Košljun"
                rating={8.5}
                distance="300 m"
              />
              <BeachCard name="Mirela Beach" rating={8.3} distance="150 m" />
              <BeachCard name="Košljun Beach" rating={7.7} distance="750 m" />
              <BeachCard name="Bok Beach" rating={7.5} distance="1,2 km" />
            </div>
          </section>
          <ApartmantAsset />
          <div className="mapouter mymap">
            <div className="gmap_canvas">
              <iframe
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Ko%C5%A1ljun%20154,%20Kosljun%20154,%2023250,&t=&z=15&ie=UTF8&iwloc=&output=embed"
                title="my-map"
              ></iframe>
            </div>
          </div>
        </Container>

        <Testimonials />
        <AboutUs />
        <div style={{ height: 100, width: 100 }} />
        <Footer />
      </main>
    </>
  );
};

export default Details;
