import React from "react";
import BeachCardSvg from "../../assets/svg/beachCardSvg";

const BeachCard = (props) => {
  const { name, distance, rating } = props;

  return (
    <div className="beach-card">
      <div className="beach-card-icon">
        <BeachCardSvg />
      </div>
      <div className="beach-card-info">
        <h5>{name}</h5>
        <p>
          <i className="fas fa-star"></i>
          {rating} rating
        </p>
        <p>
          <i className="fas fa-map-marker-alt icon-star"></i>
          {distance} from apartmant
        </p>
      </div>
    </div>
  );
};

export default BeachCard;
