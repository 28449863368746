import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Nav() {
  const [show, setShow] = useState(false);
  const location = useLocation();

  // Set show navbar
  const transitionNavBar = () => {
    if (window.scrollY > 100) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    // Listen to scroll event
    window.addEventListener("scroll", transitionNavBar);

    // Clean up function
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);

  return (
    <nav className={`nav ${show && "nav__black"}`}>
      <Link to="/" style={{ textDecoration: "none" }}>
        {/* <div className="nav-img-container">
          <img
            src="/images/logo-villa-mar-2-1.png"
            alt="logo"
            className="nav-logo-img"
            style={{ maxHeight: 100 }}
          />
          <img
            src="/images/logo-villa-mar-2-2.png"
            alt="logo"
            className="nav-logo-img logo-name"
            style={{ maxHeight: 30 }}
          />
        </div> */}
        <h1
          className="hand"
          style={{ margin: 0, color: location.pathname !== "/" && "black" }}
        >
          Villa Mar
        </h1>
      </Link>
    </nav>
  );
}

export default Nav;
