import React from "react";
import { Carousel, Container } from "react-bootstrap";
import Divider from "../Divider/Divider";
import { testemonals } from "./const";
import TestimonialsCard from "./TestimonialsCard";

const Testimonials = () => {
  return (
    <Container>
      <div className="testimonials">
        <h2>Testimonials</h2>
        <Divider />
        <Carousel className="testimonials__carousel">
          {testemonals.map((t, i) => (
            <Carousel.Item interval={5000} key={i}>
              <TestimonialsCard
                name={t.name}
                flag={t.flag}
                description={t.description}
                country={t.country}
                date={t.date}
                rating={t.rating}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Container>
  );
};

export default Testimonials;
