import React from "react";

const TestimonialsCard = (props) => {
  const { name, country, flag, description, date, rating } = props;
  return (
    <div className="testimonials__card">
      <div className="testimonials__card-left">
        <div className="testimonials__card-avatar">{name[0].toUpperCase()}</div>
        <div className="testimonials__card-flagbox">
          <h4>{name}</h4>
          <img src={flag} alt="flag" />
        </div>
        <p className="testimonials__card-rating">
          Rating <span>{rating}</span>
        </p>
        <p className="testimonials__card-country">{country}</p>
        <p className="testimonials__card-date">{date}</p>
      </div>
      <div className="testimonials__card-right">
        <p className="testimonials__card-description">
          {description}
          <br />
        </p>
      </div>
    </div>
  );
};

export default TestimonialsCard;
