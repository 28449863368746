import React from "react";

const Welcome = () => {
  return (
    <div className="welcome">
      <video
        autoPlay
        muted
        loop
        controls
        style={{ objectFit: "cover", height: "100%", width: "100%" }}
      >
        <source src="/video/welcome.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default Welcome;
