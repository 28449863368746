import React from "react";
import { Container } from "react-bootstrap";

import { apartmants } from "./const";
import Divider from "../Divider/Divider";
import Apartmant from "../Apartmant/Apartmant";

const ApartmantList = () => {
  return (
    <div id="apartmant__list">
      <Container>
        <h1>Apartmants</h1>
        <Divider />
        {/*  {apartmants.map((a, i) => (
          <ApartmantCard
            key={i}
            size={a.size}
            description={a.description}
            link={a.link}
            bathroom={a.bathroom}
            name={a.name}
            price={a.price}
            thumbnail={a.thumbnail}
            doubleBed={a.doubleBed}
            adults={a.adults}
            premium={a.premium}
          />
        ))} */}
        <div className="apartmant-grid">
          {apartmants.map((a, i) => (
            <Apartmant
              key={i}
              link={a.link}
              bathroom={a.bathroom}
              name={a.name}
              thumbnail={a.thumbnail}
              doubleBed={a.doubleBed}
              adults={a.adults}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ApartmantList;
