import React from "react";
import { Carousel, Container } from "react-bootstrap";

const Gallery = ({ apartment }) => {
  return (
    <Container>
      <Carousel>
        {apartment &&
          Array.isArray(apartment.images) &&
          apartment.images.map((t, i) => (
            <Carousel.Item interval={5000} key={i}>
              <img
                style={{ maxHeight: "70vh", width: "100%" }}
                src={t}
                alt="First slide"
              />
            </Carousel.Item>
          ))}
      </Carousel>
    </Container>
  );
};

export default Gallery;
