//const scrollOffset = 150;

export const scrollToApartmants = () => {
  const apartmants = document.getElementById("apartmant__list");
  /* const apartmantsTop = apartmants.getBoundingClientRect().top;
  const offsetPosition = apartmantsTop - scrollOffset;
  window.scrollTo({
    behavior: "smooth",
    top: offsetPosition + apartmantsTop,
  }); */

  apartmants.scrollIntoView({
    behavior: "smooth",
  });
};

export const scrollToContact = () => {
  const contact = document.querySelector(".aboutus");
  contact.scrollIntoView({
    behavior: "smooth",
  });
};

export const generatePhotoUrl = (name, num) => {
  const arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(`/images/apartments/${name}/${i + 1}.jpg`);
  }

  return arr;
};
